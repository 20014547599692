.banner {
  background-size: cover;
  background-position: center;
  height: 95vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner h1 {
  color: #FFFFFF;
  text-shadow: 3px 5px 2px #474747;
  font-size: 60px;
}

.banner p {
  color: #FFFFFF;
  text-shadow: 3px 5px 2px #474747;
  opacity: .95;
  font-size: 25px;
}

section {
  position: relative;
  overflow: hidden;
  width: 700px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

section img {
  position: absolute;
  width: 90%;
  object-fit: cover;
  filter: drop-shadow(3px 5px 2px #474747);
}

section .image img {
  width: 600px;
  position: relative;
  filter: drop-shadow(3px 5px 2px #474747);
}

.arrowRow {
  height: 5vh;
}
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  /* top: 50%; */
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}


.arrow:before {
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
