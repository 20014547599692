body {
  background-color: #0D1117;
}

body::-webkit-scrollbar {
  display: none;
}

.page {
  margin: 0;
  height: 100vh;

  display: grid;
  grid-template-areas:
  "main";
  grid-template-columns: 1fr;

  color: #F1F1F1;
}

main {
  grid-area: main;
  margin: auto;
}

/* body {
  cursor: none;
} */
