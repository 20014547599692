.about {
  background-size: cover;
  background-position: center;
  height: 95vh;
  display: flex;
  align-items: center;
}

/* .about h2 { */
  /* color: #FFFFFF; */
  /* text-shadow: 3px 5px 2px #474747; */
  /* font-size: 50px; */
/* } */

.image {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  height: 400px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.text h2 {
  text-shadow: 3px 5px 2px #474747;
  margin-bottom: 25px;
}

.text p {
  font-size: 20px;
  margin-bottom: 8px;
  text-indent:10px
}

/* Scroll Arrow */
.arrowRow {
  height: 5vh;
}
.arrow,
.arrow:before {
  position: absolute;
  left: 50%;
}

.arrow {
  width: 40px;
  height: 40px;
  /* top: 50%; */
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px #fff solid;
  border-bottom: 2px #fff solid;
}


.arrow:before {
  content: '';
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  border-right: 1px #fff solid;
  border-bottom: 1px #fff solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}
