
/* Loading Page */
.loader {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #0D1117;
  position: absolute;
}

.loader div {
  height: 100px;
  width: 100px;
  border: 15px solid #45474b;
  border-top-color: #f1f1f1f1;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  100% {
      transform: rotate(360deg);
  }
}

.content {
  display: none;
}
/* Loading Page End */
